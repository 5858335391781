import HeaderBannerSmall from "../components/HeaderBannerSmall";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { getTipoVinos } from "../utils/APICalls";
import BotonVino from "../components/BotonVino";
import FilaVino from "../components/FilaVino";
import {
  comeFromTopVariants,
  opacityStaggerVariants,
  opacityRouteVariants,
  ChakraBox,
} from "../utils/animations";
import { useContext, useState, useEffect } from "react";
import { LanguajeContext } from "../App";
import { useParams } from "react-router-dom";

const apiImages = "api/";

const Vinos = () => {
  const [tiposVinos, setTiposVinos] = useState();
  const [activeVino, setActiveVino] = useState();
  const { lang } = useContext(LanguajeContext);

  const { id } = useParams();

  const textVinos = lang
    ? [
        `<b>Callia</b> is the perfect wine for enjoying that time you’ve been waiting so long for; that moment when you can finally reconnect to friends and family, or whatever it is that gives you pleasure.
        Let <b>Callia</b> share those moments of getting #BackToYourself, those times when everything feels right.
        `,
        `<b>Callia Tardío</b> is the perfect refreshing wine for that time you’ve been waiting so long for: that moment when you can finally reconnect with yourself by meeting friends, chatting, or being in nature.
        Let <b>Callia Tardío</b> share those moments of getting #BackToYourself, those times when everything feels right.
        <b>Callia Tardío</b>’s perfect balance of fruity aroma and freshness makes it perfect to enjoy as an aperitif or for accompanying a meal.
        `,
        `<b>ContraCara</b>, where the wild spirit and harmony meet. Two opposing faces of the Pedernal Valley. On the one hand, the wildness that is provided by the forces of nature, the complexity of the soils and the freshness of the meltwater that bathes our vineyards. On the other hand, harmony, which is reflected in the elegance of the aromas, the structure of the flavours, and the balance of the textures of an exceptional wine.
        <b>ContraCara</b>, where the wild spirit finds its harmony.
        `,

        `Here’s to those singular conversations that can only happen during those most anticipated encounters.
        How do you think the rendezvous will go?
        Our <b>Esperado</b> line of wines are aged for a long period, and we harvest our grapes a little later, waiting for them to ripen a bit longer on the vine in order to produce wines that are innovative and expressively fruity.`,
        `You’ll find the fruity profile of <b>Callia</b> sparkling wines quite agreeable and always close at hand. They’re just perfect for sharing good times or just relaxing.`,
      ]
    : [
        `<b>Callia</b> es un vino perfecto para acompañar ese momento que tanto esperás. Cuando elegís conectarte con eso que te desconecta: amigos, familia, o reencontrarte con lo que te gusta hacer.
        <b>Callia</b> te acompaña para compartir tus momentos de #VolverAVos con lo que te ayuda a estar bien.`,
        `<b>Callia Tardío</b> es un vino perfecto para refrescar ese momento que tanto esperás. El momento de conectarte con eso que te desconecta: las charlas, los encuentros, el contacto con la naturaleza. <b>Callia Tardío</b> te acompaña para compartir tus momentos de #VolverAVos con lo que te ayuda a estar bien. El equilibrio ideal entre el aroma frutado y la frescura de <b>Callia Tardío</b> lo hace perfecto para consumir como aperitivo o acompañar una comida.`,
        `<b>ContraCara</b>, donde el espíritu salvaje y el equilibrio se encuentran. Dos caras opuestas del Valle de Pedernal. Por un lado, lo salvaje: la fuerza de la naturaleza, la complejidad de nuestros suelos y la frescura del agua de deshielo que baña nuestros viñedos. Por otro lado, lo armónico: la elegancia de los aromas, la estructura de los sabores y el balance de las texturas de un vino excepcional. 
        <b>ContraCara</b> donde el espíritu salvaje encuentra su equilibrio.`,
        `Esas conversaciones únicas que se dan solamente en el encuentro más esperado. ¿Qué esperas vos de este encuentro?
         <b>Esperado</b> es una línea de vinos de maduración prolongada. Esperamos que las uvas maduren más tiempo en la planta para luego cosecharlas y elaborar un vino de expresión frutal y estilo innovador`,
        `Los espumantes <b>Callia</b> nos ofrecen un perfil frutado, amigable y cercano, ideal para relajarse y disfrutar de buenos momentos compartidos.`,
      ];

  useEffect(() => {
    getTipoVinos(lang).then((json) => {
      if (json.status === "KO") {
        alert("Error encontrando el listado de vinos.");
      } else {
        let obj = {};
        json.extraInfo.map((unVino) => (obj[unVino.nroOrden] = unVino));
        const findVino = json.extraInfo.findIndex((vino, i) => {
          if (lang) {
            return vino.nombreME.toLowerCase() === id;
          } else {
            return vino.nombre.toLowerCase() === id;
          }
        });

        setActiveVino(Object.keys(obj)[findVino] || Object.keys(obj)[0]);
        
        setTiposVinos(obj);
      }
    });
  }, [id, lang]);

  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      {tiposVinos && activeVino ? (
        <HeaderBannerSmall
          title={lang ? "Wines" : "Vinos"}
          image={apiImages + tiposVinos[activeVino].pathFoto}
        />
      ) : null}
      <Box pt={16}>
        {tiposVinos && (
          <ChakraBox
            variants={opacityStaggerVariants}
            initial="hidden"
            animate="show"
            className="tabs-flex"
          >
            {Object.keys(tiposVinos).map((unTipo) => (
              <ChakraBox
                variants={comeFromTopVariants}
                key={tiposVinos[unTipo].id}
              >
                <BotonVino
                  vino={
                    lang
                      ? tiposVinos[unTipo].nombreME
                      : tiposVinos[unTipo].nombre
                  }
                  idVino={unTipo}
                  setActiveVino={setActiveVino}
                  active={activeVino}
                />
              </ChakraBox>
            ))}
          </ChakraBox>
        )}
      </Box>
      {tiposVinos &&
      
        Object.keys(tiposVinos).map((unTipo, index) => (
          <FilaVino
            vino={tiposVinos[unTipo]}
            key={tiposVinos[unTipo].id}
            active={activeVino}
            text={textVinos[index]}
          />
        ))}
    </motion.div>
  );
};

export default Vinos;
