export function capitalize(string) {
  let newString = string.toLowerCase();
  return newString[0].toUpperCase() + newString.substring(1);
}

export function titleCase(str) {
  if (!str || typeof str !== "string") return ""; // Handle null, undefined, or non-string input
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}
